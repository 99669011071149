import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import React from 'react';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '16px',
  color: theme.palette.neutral[900],
  lineHeight: '150%',
}));

const FrameItems = ({ boldTitle, paragraph }) => {
  return (
    <Box sx={{ mb: 3, display: 'flex', pl: 3 }}>
      <ul>
        <li>
          <StyledTypography>
            <strong>{boldTitle}</strong>
            {paragraph}
          </StyledTypography>
        </li>
      </ul>
    </Box>
  );
};

export default FrameItems;
