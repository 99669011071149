import { AxiosRequestConfig } from 'axios';
import { getAuthToken } from '../hooks/useFirebase';

export const authenticationInterceptor = async (config: AxiosRequestConfig) => {
  const authorization_token = await getAuthToken();
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
    },
  };
  if (authorization_token) {
    newConfig.headers.Authorization = `Bearer ${authorization_token}`;
  }

  return newConfig;
};
