import React, { Children } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, Typography, Box } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import styled from '@emotion/styled';
import Markdown from '../markdown';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.neutral[900],
    color: theme.palette.background.paper,
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: 'DM Sans',
    textTransform: 'none',
    padding: '16px',
    gap: '10px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.neutral[900],
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.neutral[300],
}));

const StyledTableCellSubtitle = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.neutral[900],
    color: theme.palette.background.paper,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'DM Sans',
    textTransform: 'none',
    lineHeight: '157%',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.neutral[900],
    textTransform: 'none',
    fontFamily: 'DM Sans',
    lineHeight: '150%',
  },
}));

const TableContent = ({
  title,
  content,
  paragraph,
  subtitle,
  items,
  body,
  secondBody,
  additionalPurposes,
  left,
  markdown,
}) => {
  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          {title && <StyledTableCell align={left ? 'left' : 'center'}>{title}</StyledTableCell>}
          {subtitle && <StyledTableCellSubtitle align={left ? 'left' : 'center'}>{subtitle}</StyledTableCellSubtitle>}
        </TableHead>
        <TableBody>
          <TableRow>
            {items && (
              <TableCell>
                {items.map((item) => (
                  <Box sx={{ mb: 3, pl: 4 }}>
                    <ul>
                      <li>
                        <strong>{item.boldTitle}</strong>
                        {item.paragraph}
                      </li>
                    </ul>
                  </Box>
                ))}
              </TableCell>
            )}
            {body && (
              <TableCell>
                {body.map((item) => (
                  <Box sx={{ mb: 3, pl: 4 }}>
                    <ul>
                      <li>{item}</li>
                    </ul>
                  </Box>
                ))}
                {additionalPurposes && (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '175%',
                        fontWeight: 500,
                      }}
                    >
                      {additionalPurposes}
                    </Typography>
                  </Box>
                )}
                {secondBody &&
                  secondBody.map((item) => (
                    <Box sx={{ mb: 3, pl: 4 }}>
                      <ul>
                        <li>{item}</li>
                      </ul>
                    </Box>
                  ))}
              </TableCell>
            )}
            {markdown && (
              <StyledTableCell>
                <Markdown source={markdown} />
              </StyledTableCell>
            )}
            {paragraph && <StyledTableCell>{paragraph}</StyledTableCell>}
            {content && <StyledTableCellSubtitle>{content}</StyledTableCellSubtitle>}
          </TableRow>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default TableContent;
