import React from 'react';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { graphql, navigate } from 'gatsby';
import styled from '@emotion/styled';
import { ArrowForward } from '@mui/icons-material';
import Layout from '../components/layout/layout';
import { theme } from '../theme';

const StyledBox = styled(Box)(({ theme, isMdUp }) => ({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '90%',
  height: '560px',
  margin: '0 auto',
  flexDirection: 'row',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

const Success = () => {
  const isMdUp = useMediaQuery(
    () => {
      return theme.breakpoints.up('sm');
    },
    {
      defaultMatches: true,
      noSsr: false,
    }
  );

  return (
    <>
      <Layout>
        <StyledBox isMdUp={isMdUp} component="main">
          <Stack alignItems="center" sx={{ maxWidth: '600px' }}>
            <Typography
              sx={{
                weight: 700,
                LineHeight: '56px',
                width: isMdUp ? '700px' : '400px',
                fontSize: '56px',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 2,
              }}
              letterSpacing="-2px"
              variant="H1"
            >
              Thank you for reaching out.
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                lineHeight: '24px',
                textAlign: 'center',
                mb: 2,
              }}
              variant="h5"
            >
              You have successfully submitted your message.
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'center',
                fontWeight: 400,
                mb: 2,
              }}
              variant="Primary"
            >
              We appreciate you contacting us. You should receive a confirmation
              email shortly. One of our professionals will get back to you soon.
            </Typography>
            <Button
              endIcon={<ArrowForward />}
              variant="text"
              onClick={() => {
                navigate('/');
              }}
            >
              Return to Home
            </Button>
          </Stack>
        </StyledBox>
      </Layout>
    </>
  );
};

export default Success;

export const Head = ({ data }) => (
  <title>Success | {data.template.caseName}</title>
);

export const query = graphql`
  query SuccessPage($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseName
    }
  }
`;
