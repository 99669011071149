
import snakeCase from 'lodash/snakeCase';


const transformIntoSnakeCase = (obj: object) => {
    return Object.keys(obj).reduce((snakeCaseObject, key) => {
        snakeCaseObject[snakeCase(key)] = obj[key];
        return snakeCaseObject;
    }, {});

}

export default transformIntoSnakeCase;