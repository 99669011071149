import { useState, useEffect } from 'react';
import { auth } from './useFirebase';

export default function useAuth() {
  const [loadingAuthentication, setLoadingAuthentication] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      const uid = user?.uid;
      const isAuthenticated = Boolean(uid);

      setAuthenticated(isAuthenticated);
      setLoadingAuthentication(false);
    });
  }, []);

  return {
    loadingAuthentication,
    authenticated,
  };
}
