import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React from 'react';

function PaymentForm({ onChange, email, phone, errorEmail, errorPhone }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
          Please complete one of the following fields, so we can identify your
          account.
        </Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          fullWidth
          label="Email"
          name="email"
          variant="outlined"
          onChange={onChange}
          value={email}
          error={errorEmail}
          helperText={errorEmail}
        />
      </Grid>
      <Grid item xs={12} md={5} direction="column" justifyContent="center">
        <TextField
          fullWidth
          label="Phone"
          name="phone"
          variant="outlined"
          onChange={onChange}
          value={phone}
          error={errorPhone}
          helperText={errorPhone}
        />
      </Grid>
    </>
  );
}

export default PaymentForm;
