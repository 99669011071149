import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { signIn } from '../hooks/useFirebase';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import { useSnackbar } from '../store';

export default function EmailValidation({ data }) {
  const { search } = useLocation();
  const { caseID } = data.template;
  const { openSnackbar } = useSnackbar();
  useEffect(() => {
    const attemptLogin = async () => {
      try {
        const params = new URLSearchParams(search);
        const claimID = params.get('claimID');
        const key = params.get('key');
        await signIn(`${claimID}${caseID}@claimscore.ai`, key);
        navigate('/claim-check');
      } catch (err) {
        openSnackbar('Sorry, there was an error.');
        navigate('/');
      }
    };
    attemptLogin();
  }, []);
  return <LoadingOverlay open={true} />;
}

export const query = graphql`
  query EmailValidationPage($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseID
    }
  }
`;
