import React, { useEffect } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Layout from '../components/layout/layout';
import {
  generateComponentProps,
  generateWrapperProps,
  wrapperMapping,
  typeMapping,
} from '../utils/dynamic-tools';
import { useSessionStorage } from '../hooks/useSessionStorage';

const ClaimInstructions = ({ data, location }) => {
  const [caseSessionData, setCaseSessionData] = useSessionStorage(
    data.template.caseID
  );

  const { instructions } = data.template;

  const thereAreInstructions =
    Array.isArray(instructions) && instructions.length > 0;

  useEffect(() => {
    !thereAreInstructions && navigate('/claim-form');
  }, [instructions, location]);

  const handleReadConfirmation = () => {
    setCaseSessionData({ ...caseSessionData, hasReadInstructions: true });
    navigate('/claim-form');
  };

  if (!thereAreInstructions) {
    return null;
  }

  return (
    <>
      <Layout>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
            Submit a Claim
          </Typography>
        </Box>
        <Box sx={{ mb: 8 }}>
          <Breadcrumbs separator="→" aria-label="breadcrumb">
            {[
              <Link key="1" to="/">
                <Typography
                  sx={{
                    fontSize: {
                      xs: '16px',
                      md: '32px',
                      color: '#65748B',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Home
                </Typography>
              </Link>,

              <Typography key="2" sx={{ fontSize: { xs: '16px', md: '32px' } }}>
                Claim Instructions
              </Typography>,
            ]}
          </Breadcrumbs>
        </Box>
        {data.template.instructions &&
          data.template.instructions.map((element, i) => {
            const Wrapper = wrapperMapping[element.wrapper];
            const Content = typeMapping[element.type];
            const elementProps = generateComponentProps(element);
            const wrapperProps = generateWrapperProps(element);
            return (
              <Box sx={{ mb: 2 }} key={i}>
                <Wrapper {...wrapperProps}>
                  <Content {...elementProps} />
                </Wrapper>
              </Box>
            );
          })}
        <Button variant="contained" onClick={handleReadConfirmation}>
          I have read the instructions
        </Button>
      </Layout>
    </>
  );
};

export default ClaimInstructions;

export const Head = ({ data }) => (
  <title>Claim Instructions | {data.template.caseName}</title>
);

export const query = graphql`
  query InstructionsPage($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseID
      caseName
      instructions {
        type
        wrapper
        text
        to
      }
    }
  }
`;
