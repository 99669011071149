import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TableContent from '../table-content';
import FrameBody from '../frame-body';
import FrameItems from '../frame-items';
import Markdown from '../markdown';
import styled from '@emotion/styled';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '16px',
  color: theme.palette.neutral[900],
  lineHeight: '150%',
}));

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontWeight: 700,
  color: theme.palette.neutral[900],
}));

const StyledNumber = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginRight: '32px',
}));

const PrivacyPolicyFrames = ({ number, title, items, paragraph, body, tables, secondBody, lastBody, markdown }) => {
  return (
    <>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <StyledNumber>{number}</StyledNumber>

        <StyledTypographyTitle>{title}</StyledTypographyTitle>
      </Box>
      {paragraph && (
        <Box sx={{ mb: 3 }}>
          <StyledTypography>{paragraph}</StyledTypography>
        </Box>
      )}
      {markdown && (
        <Box sx={{ mb: 3 }}>
          <Markdown source={markdown} />
        </Box>
      )}
      {tables && (
        <Box sx={{ mb: 6 }}>
          {tables.map((tables) => (
            <Grid sx={{ display: 'flex' }}>
              <TableContent title={tables.title} content={tables.content} left />
              <TableContent title={tables.secondTitle} paragraph={tables.paragraph} left />
            </Grid>
          ))}
        </Box>
      )}
      {items && items.map((item) => <FrameItems boldTitle={item.boldTitle} paragraph={item.paragraph} />)}
      {body &&
        body.map((bodies) => (
          <FrameBody
            italicTitle={bodies.italicTitle}
            bigTitle={bodies.bigTitle}
            paragraph={bodies.paragraph}
            boldTitle={bodies.boldTitle}
            itemsList={bodies.itemsList}
            intro={bodies.intro}
            secondBody={bodies.secondBody}
            sameLine={bodies.sameLine}
          />
        ))}
      {secondBody &&
        secondBody.map((bodies) => <FrameBody italicTitle={bodies.italicTitle} itemsList={bodies.itemsList} />)}
      {lastBody && lastBody.map((items) => <Box>{items}</Box>)}
    </>
  );
};

export default PrivacyPolicyFrames;
