import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'gatsby';
import { Divider } from '@mui/material';
import Markdown from '../components/markdown';
import Reminder from '../components/reminder';

export const typeMapping = {
  markdown: Markdown,
  reminder: Markdown,
  button: Button,
  divider: Divider,
};

export function generateComponentProps(element) {
  switch (element.type) {
    case 'markdown':
    case 'reminder':
      return {
        source: element.text,
      };
    case 'button':
      return {
        LinkComponent: Link,
        to: element.to,
        variant: 'contained',
        sx: {
          my: 2,
          color: 'white',
          display: { xs: 'flex', sm: 'inline-block' },
          px: { md: '44px' },
          py: 2,
          fontSize: '15px',
          fontWeight: 400,
        },
        disableElevation: true,
        children: element.text,
      };
    case 'divider':
      return { sx: { mb: 3 } };
    default:
      throw new Error(
        `Invalid type on claim-instructions generator. ${element.type}`
      );
  }
}

export const wrapperMapping = {
  none: React.Fragment,
  reminder: Reminder,
};

export function generateWrapperProps(element) {
  switch (element.wrapper) {
    case 'none':
      return {};
    case 'reminder':
      return {
        title: 'Reminder: ',
        description: undefined,
      };
    default:
      throw new Error(
        `Invalid wrapper on claim-instructions generator. ${element.type}`
      );
  }
}
