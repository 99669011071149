import Axios from 'axios';
import { authenticationInterceptor } from './interceptors';

const claimsClient = Axios.create({
  baseURL: `${process.env.GATSBY_CLAIMS_SERVICE_URL}`,
});

// CLAIMS
export const submitClaim = (requestBody, headers = {}) =>
  claimsClient.post('/claims', requestBody, { headers });

// export const sendSubmitClaimEmail = (requestBody, headers) => claimsClient.post('/claims/submit-claim-email', requestBody, { headers });

export const sendContactEmails = (requestBody, headers = {}) =>
  claimsClient.post('/claims/contact-email', requestBody, { headers });

export const getClaimantData = (caseID) =>
  claimsClient.get(`/claims/${caseID}/claimant`);

export const submitClaimForHashing = (requestBody, headers = {}) =>
  claimsClient.post('/claims/hash', requestBody, { headers });
// INTERCEPTORS
claimsClient.interceptors.request.use(authenticationInterceptor);
