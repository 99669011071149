import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { graphql, navigate } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../components/layout/layout';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import { useSnackbar } from '../store';
import { SnackBar } from '../components/snackbar';
import { sendContactEmails } from '../services/claims.service';

const StyledTextField = styled(TextField)(() => ({
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  height: 0,
  width: 0,
  zIndex: -1,
}));

const Contact = ({ data }) => {
  const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY;
  const [captcha, setCaptcha] = useState();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      uniqueID: '',
      submissionID: '',
      message: '',
      direction: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(255).required('First Name is required'),
      lastName: Yup.string().max(255).required('Last Name is required'),
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      message: Yup.string().required('Message is required'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const headers = {
          'x-recaptcha-token': captcha,
        };

        const body = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          submissionID: values.submissionID,
          message: values.message,
          caseName: data.template.caseName,
          referrer: document.referrer,
          direction: values.direction,
        };

        await sendContactEmails(body, headers);
        navigate('/success');
      } catch (e) {
        openSnackbar('Unable to send the contact request', 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <LoadingOverlay open={loading} />
      <Layout>
        <SnackBar>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
                Contact Us
              </Typography>
            </Box>
            <Box sx={{ mb: 8 }}>
              <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
                If you would like to contact the Settlement Administrator,
                please submit the below information.
              </Typography>
            </Box>
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Contact Form
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      sx={{ mb: 3, pr: { xs: 0, md: 3 } }}
                      label="First Name"
                      name="firstName"
                      variant="outlined"
                      placeholder="John"
                      value={formik.values.firstName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.firstName && formik.errors.firstName
                      )}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      sx={{ mb: 3 }}
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      placeholder="Doe"
                      value={formik.values.lastName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.lastName && formik.errors.lastName
                      )}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      sx={{ mb: 3, pr: { xs: 0, md: 3 } }}
                      label="Email"
                      type="email"
                      name="email"
                      variant="outlined"
                      placeholder="johndoe@email.com"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.email && formik.errors.email
                      )}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      sx={{ mb: 3 }}
                      label="Phone"
                      name="phone"
                      variant="outlined"
                      placeholder="(123) 555 - 6789"
                      value={formik.values.phone}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.phone && formik.errors.phone
                      )}
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      sx={{ mb: 3 }}
                      label="Claim ID or Submission ID"
                      name="submissionID"
                      variant="outlined"
                      placeholder="XXXXXXXXXX"
                      value={formik.values.submissionID}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.submissionID &&
                          formik.errors.submissionID
                      )}
                      helperText={
                        formik.touched.submissionID &&
                        formik.errors.submissionID
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      fullWidth
                      sx={{ mb: 3 }}
                      label="Message"
                      name="message"
                      variant="outlined"
                      value={formik.values.message}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.message && formik.errors.message
                      )}
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    />
                  </Grid>
                  <StyledTextField
                    label="direction"
                    name="direction"
                    value={formik.values.direction}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    placeholder="Your direction here"
                  />
                </Grid>
              </CardContent>
            </Card>
            {RECAPTCHA_KEY && (
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={(value) => {
                  setCaptcha(value);
                }}
              />
            )}
            <Button
              sx={{
                my: 2,
                px: 9,
                py: 2,
                width: { xs: '100%', md: 'auto' },
              }}
              color="primary"
              disabled={
                !(
                  formik.values.firstName &&
                  formik.values.lastName &&
                  formik.values.email &&
                  formik.values.message &&
                  captcha
                )
              }
              size="large"
              type="submit"
              variant="contained"
            >
              <Typography>Submit</Typography>
            </Button>
          </form>
        </SnackBar>
      </Layout>
    </>
  );
};

export default Contact;

export const Head = ({ data }) => (
  <title>Contact | {data.template.caseName}</title>
);

export const query = graphql`
  query ContactPage($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseName
    }
  }
`;
