import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import React from 'react';

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '16px',
  color: theme.palette.neutral[900],
  lineHeight: '150%',
}));

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '24px',
  fontFamily: 'DM Sans',
  color: theme.palette.neutral[900],
  lineHeight: '100%',
  fontStyle: 'normal',
}));

const FrameBody = ({ bigTitle, itemsList, boldTitle, intro, paragraph, italicTitle, sameLine }) => {
  return (
    <>
      {bigTitle && (
        <Box sx={{ mb: 2 }}>
          <StyledTypographyTitle>{bigTitle}</StyledTypographyTitle>
        </Box>
      )}
      {intro && (
        <Box sx={{ mb: 2, display: 'flex' }}>
          <StyledTypography>{intro}</StyledTypography>
        </Box>
      )}
      {boldTitle && (
        <Box sx={{ mb: 2, display: 'flex' }}>
          <StyledTypography>
            <strong>{boldTitle}</strong>
            {sameLine}
          </StyledTypography>
        </Box>
      )}
      {paragraph && (
        <Box sx={{ mb: 3 }}>
          <StyledTypography>{paragraph}</StyledTypography>
        </Box>
      )}
      {italicTitle && (
        <Box sx={{ mb: 3 }}>
          <StyledTypography sx={{ fontStyle: 'italic' }}>{italicTitle}</StyledTypography>
        </Box>
      )}
      {itemsList && (
        <Box sx={{ mb: 3 }}>
          {itemsList.map((content, index) => (
            <Box key={index} sx={{ pl: 3 }}>
              <ul>
                <li>
                  <StyledTypography>{content}</StyledTypography>
                </li>
              </ul>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default FrameBody;
