import Axios from 'axios';
import { authenticationInterceptor } from './interceptors';

const claimsClient = Axios.create({
  baseURL: `${process.env.GATSBY_CLAIMS_SERVICE_URL}`,
});

//PAYMENTS
export const submitPayment = (requestBody, headers = {}) =>
  claimsClient.post('/payments', requestBody, { headers });

// INTERCEPTORS
claimsClient.interceptors.request.use(authenticationInterceptor);
