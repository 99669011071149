// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getAuth,
} from 'firebase/auth';
import firebaseConfig from '../../firebase-config.json';

const firebaseApp = initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
isSupported().then((supported) => {
  if (supported) {
    getAnalytics(app);
  }
});
const storage = getStorage(app);

export const firestore = getFirestore(app);
export const auth = getAuth(firebaseApp);
export const useFileUploader = () => {
  const fileUploader = async (url, file) => {
    const storageRef = ref(storage, url);
    return uploadBytes(storageRef, file);
  };
  return fileUploader;
};

export const getAuthToken = () => {
  return auth.currentUser?.getIdToken();
};

export const getUserID = () => {
  return auth.currentUser?.uid;
};

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signOutFromFirebase = () => {
  return signOut(auth);
};
