import { Button, Grid, Typography } from '@mui/material';
import React from 'react';

function PaymentFormByMail() {
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
          Your check will be mailed to the address you verified on the previous
          screen.
        </Typography>
      </Grid>
    </>
  );
}

export default PaymentFormByMail;
