import React from 'react';
import { Box, Typography } from '@mui/material';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '../components/layout/layout';
import TableContent from '../components/table-content';
import PrivacyPolicyFrames from '../components/privacyPolicy-frames';

const StyledBox = styled(Box)`
  display: flex;
  margin-bottom: 3;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '250%',
  fontWeight: 500,
  fontSize: '12px',
  color: theme.palette.primary.main,
}));

const PrivacyPolicy = ({ data }) => {
  const { privacyPolicy } = data.template;
  return (
    <Layout>
      <Box sx={{ mb: 3 }}>
        <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
          {privacyPolicy.title}
        </Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <StyledTypography variant="overline">
          {privacyPolicy.update}
        </StyledTypography>
      </Box>
      {privacyPolicy.body.map((value, index) => (
        <Box sx={{ mb: 3 }} key={index}>
          <Typography
            sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '150%' }}
          >
            {value}
          </Typography>
        </Box>
      ))}
      <TableContent
        title={privacyPolicy.mainTable.title}
        paragraph={privacyPolicy.mainTable.paragraph}
        markdown={privacyPolicy.mainTable.markdown}
      />
      <StyledBox sx={{ mb: 5 }}>
        {privacyPolicy.secondaryTables.map((value, index) => (
          <TableContent
            key={index}
            title={value.title}
            body={value.body}
            items={value.items}
            subtitle={value.subtitle}
            additionalPurposes={value.additionalPurposes}
            secondBody={value.secondBody}
          />
        ))}
      </StyledBox>
      {privacyPolicy.frames.map((frames, index) => (
        <PrivacyPolicyFrames
          key={index}
          number={frames.number}
          title={frames.title}
          paragraph={frames.paragraph}
          tables={frames.tables}
          body={frames.body}
          secondBody={frames.secondBody}
          lastBody={frames.lastBody}
          items={frames.items}
          markdown={frames.markdown}
        />
      ))}
    </Layout>
  );
};

export default PrivacyPolicy;

export const Head = () => <title>Privacy Policy</title>;

export const query = graphql`
  query PrivacyPolicy($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      privacyPolicy {
        body
        frames {
          body {
            additionalPurposes
            bigTitle
            body
            boldTitle
            content
            intro
            italicTitle
            itemsList
            items {
              additionalPurpose
              boldTitle
              content
              paragraph
              subtitle
            }
            paragraph
            sameLine
            secondBody
            secondTitle
            subtitle
            title
            markdown
          }
          items {
            additionalPurposes
            bigTitle
            body
            boldTitle
            content
            intro
            italicTitle
            items {
              additionalPurpose
              boldTitle
              content
              paragraph
              subtitle
            }
            paragraph
            sameLine
            secondBody
            secondTitle
            subtitle
            title
          }
          lastBody
          number
          paragraph
          markdown
          secondBody {
            additionalPurposes
            bigTitle
            body
            boldTitle
            content
            intro
            italicTitle
            itemsList
            items {
              additionalPurpose
              boldTitle
              content
              paragraph
              subtitle
            }
            paragraph
            sameLine
            secondBody
            secondTitle
            subtitle
            title
          }
          tables {
            additionalPurposes
            bigTitle
            body
            boldTitle
            content
            intro
            italicTitle
            items {
              additionalPurpose
              boldTitle
              content
              paragraph
              subtitle
            }
            paragraph
            sameLine
            secondBody
            secondTitle
            subtitle
            title
          }
          title
        }
        mainTable {
          markdown
          additionalPurposes
          bigTitle
          body
          boldTitle
          content
          intro
          italicTitle
          items {
            additionalPurpose
            boldTitle
            content
            paragraph
            subtitle
          }
          paragraph
          sameLine
          secondBody
          secondTitle
          title
          subtitle
        }
        secondaryTables {
          additionalPurposes
          bigTitle
          body
          boldTitle
          content
          intro
          italicTitle
          items {
            additionalPurpose
            boldTitle
            content
            paragraph
            subtitle
          }
          paragraph
          sameLine
          secondBody
          secondTitle
          subtitle
          title
        }
        title
        update
      }
    }
  }
`;
