import { Paper, Typography, Box } from '@mui/material';
import React from 'react';

function Reminder({ title, description, children }) {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: 'RGBA(209,67,67,0.06)', p: '20px' }}
    >
      <Typography
        sx={{
          color: '#D14343',
          fontWeight: 500,
          fontSize: '24px',
        }}
      >
        {title}
      </Typography>
      <Box sx={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
        {description || children}
      </Box>
    </Paper>
  );
}

export default Reminder;
