import { useState } from 'react';

export function useSessionStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window !== 'undefined') {
      const item = window?.sessionStorage?.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    }
  });

  const setValue = (value) => {
    if (typeof window !== 'undefined') {
      setStoredValue(value);
      window?.sessionStorage?.setItem(key, JSON.stringify(value));
    }
  };

  return [storedValue, setValue];
}
