import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

function PaymentFormDeposit({
  onChange,
  accountNumber,
  routingNumber,
  accountType,
}) {
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
          Please provide all of the following information, so we can identify
          your account.
        </Typography>
      </Grid>
      <Grid item xs={12} md={3.5}>
        <TextField
          fullWidth
          label="Account Number"
          name="accountNumber"
          variant="outlined"
          onChange={onChange}
          value={accountNumber}
        />
      </Grid>
      <Grid item xs={12} md={3.5}>
        <TextField
          fullWidth
          label="Routing Number"
          name="routingNumber"
          variant="outlined"
          onChange={onChange}
          value={routingNumber}
        />
      </Grid>
      <Grid item xs={12} md={3.5}>
        <FormControl fullWidth>
          <InputLabel id="account-type-select-label">Account Type</InputLabel>
          <Select
            value={accountType}
            labelId="account-type-select-label"
            id="account-type-select"
            label="Account Type"
            name="accountType"
            onChange={onChange}
          >
            <MenuItem value={'Checking'}>Checking</MenuItem>
            <MenuItem value={'Savings'}>Savings</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default PaymentFormDeposit;
