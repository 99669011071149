import React from 'react';
import Typography from '@mui/material/Typography';
import { graphql, Link } from 'gatsby';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Grid } from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import styled from '@emotion/styled';
import Layout from '../components/layout/layout';

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: 2,
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '32px',
  color: theme.palette.neutral[300],
}));

const StyledTrendingFlatIcon = styled(TrendingFlatIcon)(({ theme }) => ({
  alignItems: 'center',
  padding: '0px',
  marginTop: 2.5,
  color: theme.palette.neutral[300],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  marginBottom: 1,
  paddingTop: 2,
  paddingBottom: 2,
  fontWeight: 500,
  paddingRight: 3,
  paddingLeft: 3,
  lineHeight: 1.25,
  justifyContent: 'space-between',
  textAlign: 'start',
  color: theme.palette.neutral[900],
}));

const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ImportantDocuments = ({ data }) => {
  const { documents } = data.template.home;

  const firstHalfDocs = documents.filter(
    (doc, index) => index < documents.length / 2
  );
  const secondHalfDocs = documents.filter(
    (doc, index) => index > documents.length / 2
  );

  return (
    <Layout>
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '56px',
            lineHeight: '56px',
            letterSpacing: '-2px',
          }}
        >
          Important Documents
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Button LinkComponent={Link} to={'/'} sx={{ p: '0px' }}>
            <StyledTypography
              textAlign="center"
              display="inline"
              sx={{ textDecoration: 'underline' }}
            >
              Home
            </StyledTypography>
          </Button>
          <StyledTrendingFlatIcon />
          <StyledTypography variant="body2">
            &nbsp;Important Documents.
          </StyledTypography>
        </Box>
      </Box>
      <Grid container spacing={7}>
        <Grid item xs={12} md={6}>
          {firstHalfDocs.map((doc) => (
            <a
              href={doc.url}
              rel="noopener noreferrer"
              target="_blank"
              key={doc.name}
            >
              <Button
                endIcon={<ArrowForwardIosIcon sx={{ color: '#3E8682' }} />}
                sx={{
                  backgroundColor: '#FFFFFF',
                  width: '100%',
                  mb: 1,
                  py: 2,
                  fontWeight: 500,
                  px: 3,
                  lineHeight: 1.25,
                  justifyContent: 'space-between',
                  textAlign: 'start',
                  color: 'black',
                }}
              >
                {doc.name}
              </Button>
            </a>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {secondHalfDocs.map((doc) => (
            <a
              href={doc.url}
              key={doc.name}
              rel="noopener noreferrer"
              target="_blank"
            >
              <StyledButton endIcon={<StyledArrowForwardIosIcon />}>
                {doc.name}
              </StyledButton>
            </a>
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ImportantDocuments;

export const Head = () => <title>Important Documents</title>;

export const query = graphql`
  query ImportantDocuments($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      home {
        documents {
          id
          name
          url
        }
      }
    }
  }
`;
